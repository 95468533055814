
const initCheckIn = () => {
  const getLocationButton = document.getElementById('share-location');
  if (getLocationButton) {
    getLocationButton.addEventListener('click', (event) => {
      event.preventDefault();
      const latitudeInput = document.getElementById('latitude');
      const longitudeInput = document.getElementById('longitude');
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        latitudeInput.value = latitude;
        longitudeInput.value = longitude;
        getLocationButton.innerText = 'Localização compartilhada!';
      });
    });
  }
}

export default initCheckIn;
